<template>
  <v-container grid-list-lg fluid p-0>
    <v-layout v-if="isInstagram" wrap>
      <v-flex md6 sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-images"
          :title="influencerChannel.media_count | local_numbers"
          subtitle="Posts"
          tooltip="Doesn't include stories"
          :flat="outlined"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
      <v-flex md6 sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-users"
          :title="influencerChannel.followers | local_numbers"
          subtitle="Followers"
          :flat="outlined"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
      <v-flex sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-bullseye"
          :title="reach | local_numbers"
          subtitle="Reach"
          tooltip="Total reach of the influencer's posts in the last 14 days"
          :flat="outlined"
          :blur="! hasSubscription"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
      <v-flex sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-eye"
          :title="impressions | local_numbers"
          subtitle="Impressions"
          tooltip="Total impressions of the influencers posts and stories in the last 14 days"
          :flat="outlined"
          :blur="! hasSubscription"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card v-if="stats" :outlined="outlined">
          <v-card-title>
            <div>
              <div class="headline">Follower countries</div>
              <span class="subtitle-1 light-grey"
                >Top 5 countries of followers</span
              >
            </div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <div style="position: relative; overflow: hidden; z-index: 1;">
              <influencer-channel-follower-country-chart
                :value="countryStats"
              ></influencer-channel-follower-country-chart>

              <v-overlay
                absolute
                :value="! hasSubscription"
                color="white"
                :class="{
                  'overlay-blur': ! hasSubscription
                }"
              >
                <v-btn color="success" @click="setShowSubscriptionDialog(true)"
                  >Upgrade</v-btn
                >
              </v-overlay>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      </v-flex>

      <v-flex xs12 sm6>
        <v-card v-if="stats" :outlined="outlined">
          <v-card-title>
            <div>
              <div class="headline">Follower cities</div>
              <span class="subtitle-1 light-grey"
                >Top 5 cities of followers</span
              >
            </div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <div style="position: relative; overflow: hidden; z-index: 1;">
              <influencer-channel-follower-city-chart
                :value="cityStats"
              ></influencer-channel-follower-city-chart>

              <v-overlay
                absolute
                :value="! hasSubscription"
                color="white"
                :class="{
                  'overlay-blur': ! hasSubscription
                }"
              >
                <v-btn color="success" @click="setShowSubscriptionDialog(true)"
                  >Upgrade</v-btn
                >
              </v-overlay>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      </v-flex>
      <v-flex sm12 md6 lg4>
        <v-card v-if="stats" :outlined="outlined" class="h-100">
          <v-card-title>
            <div>
              <div class="headline">Follower gender distribution</div>
              <span class="subtitle-1 light-grey"
                >Follower gender distribution</span
              >
            </div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <div
              style="position: relative;overflow: hidden; z-index: 1;"
              class="w-100"
            >
              <influencer-channel-follower-gender-chart
                :value="stats.gender_age"
              ></influencer-channel-follower-gender-chart>

              <v-overlay
                absolute
                :value="! hasSubscription"
                color="white"
                :class="{
                  'overlay-blur': ! hasSubscription
                }"
              >
                <v-btn color="success" @click="setShowSubscriptionDialog(true)"
                  >Upgrade</v-btn
                >
              </v-overlay>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      </v-flex>
      <v-flex sm12 md6 lg8>
        <v-card v-if="stats" :outlined="outlined" class="h-100">
          <v-card-title>
            <div>
              <div class="headline">Follower gender- and age distribution</div>
              <span class="subtitle-1 light-grey">&nbsp;</span>
            </div>
          </v-card-title>
          <v-card-text class="d-flex align-center">
            <div
              style="position: relative; overflow: hidden; z-index: 1;"
              class="w-100"
            >
              <influencer-channel-follower-gender-age-chart
                :value="genderAgeStats"
              ></influencer-channel-follower-gender-age-chart>

              <v-overlay
                absolute
                :value="! hasSubscription"
                color="white"
                :class="{
                  'overlay-blur': ! hasSubscription
                }"
              >
                <v-btn color="success" @click="setShowSubscriptionDialog(true)"
                  >Upgrade</v-btn
                >
              </v-overlay>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      </v-flex>

      <v-flex xs12>
        <v-card v-if="stats" :outlined="outlined">
          <v-card-title>
            <div>
              <div class="headline">Daily follower growth</div>
              <span class="subtitle-1 light-grey">&nbsp;</span>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <follower-growth-chart
              min-height="300px"
              :data="followerGrowthData"
            ></follower-growth-chart>
          </v-card-text>
        </v-card>
        <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>

    <v-layout v-if="isTiktok" wrap>
      <v-flex md4 sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-images"
          :title="influencerChannel.media_count | local_numbers"
          subtitle="Posts"
          :flat="outlined"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
      <v-flex md4 sm6 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-users"
          :title="influencerChannel.followers | local_numbers"
          subtitle="Followers"
          :flat="outlined"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
      <v-flex md4 sm12 xs12>
        <stat-widget
          v-if="stats"
          icon="fal fa-heart"
          :title="influencerChannel.total_likes | local_numbers"
          subtitle="Total Likes"
          :flat="outlined"
        ></stat-widget>
        <v-skeleton-loader
          v-else
          type="card"
          max-height="94px"
        ></v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import channel_color from "@/helpers/filters/channel_color";
import date_to_age from "@/helpers/filters/date_to_age";
import first_letter from "@/helpers/filters/first_letter";
import StatWidget from "../widgets/StatWidget";
import InfluencerChannelFollowerCountryChart from "../charts/InfluencerChannelFollowerCountryChart";
import InfluencerChannelFollowerCityChart from "../charts/InfluencerChannelFollowerCityChart";
import InfluencerChannelFollowerGenderChart from "../charts/InfluencerChannelFollowerGenderChart";
import InfluencerChannelFollowerGenderAgeChart from "../charts/InfluencerChannelFollowerGenderAgeChart";
import FollowerGrowthChart from "../charts/FollowerGrowthChart";

export default {
  filters: {
    friendly_numbers,
    local_numbers,
    utc_to_local_datetime,
    channel_color,
    date_to_age,
    first_letter
  },
  components: {
    FollowerGrowthChart,
    InfluencerChannelFollowerGenderAgeChart,
    InfluencerChannelFollowerGenderChart,
    InfluencerChannelFollowerCityChart,
    InfluencerChannelFollowerCountryChart,
    StatWidget
  },
  props: {
    influencerChannel: {
      required: true,
      type: Object,
      default: () => ({})
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    stats: null
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("subscriptions", {
      _hasSubscription: "hasSubscription"
    }),
    isInstagram() {
      return this.influencerChannel.channel.name === 'instagram';
    },
    isTiktok() {
      return this.influencerChannel.channel.name === 'tiktok';
    },
    hasSubscription() {
      return this.user && this.user.business ? this._hasSubscription : true;
    },
    followers() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.followers,
        0
      );
    },
    impressions() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.impressions,
        0
      );
    },
    reach() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.unique_profile_views,
        0
      );
    },
    countryStats() {
      if (! this.hasSubscription) {
        let demoStats = [];

        for (let i = 5; i > 1; i--) {
          demoStats.push({
            followers: i * 1000,
            country: { name: "Demo country" }
          });
        }

        return demoStats;
      }

      return this.stats.countries.slice(0, 5);
    },
    cityStats() {
      if (! this.hasSubscription) {
        let demoStats = [];

        for (let i = 5; i > 1; i--) {
          demoStats.push({
            followers: i * 1000,
            city: { city_name: "Demo country" }
          });
        }

        return demoStats;
      }

      return this.stats.cities.slice(0, 5);
    },
    genderAgeStats() {
      if (! this.hasSubscription) {
        let demoStats = [];

        for (let i = 5; i > 1; i--) {
          demoStats.push({
            followers: i * 1000,
            age: i + "-100",
            gender: "male"
          });
          demoStats.push({
            followers: i * 1000,
            age: i + "-100",
            gender: "female"
          });
        }

        return demoStats;
      }

      return this.stats.gender_age;
    },
    followerGrowthData() {
      let stats = this.stats.daily.slice(0, 13);
      let data = {
        dates: [],
        values: []
      };

      stats.forEach(value => {
        data.dates.push(value.date);
        data.values.push(value.followers);
      });

      data.dates.reverse();
      data.values.reverse();

      return data;
    }
  },
  watch: {
    influencerChannel: {
      immediate: true,
      handler() {
        this.getStats();
      }
    }
  },
  methods: {
    ...mapActions("subscriptions", ["setShowSubscriptionDialog"]),
    ...mapActions("core", ["getInfluencerStats"]),
    getStats() {
      this.stats = null;
      let params = {
        influencer_channel_uuid: this.influencerChannel.uuid
      };
      this.getInfluencerStats(params).then(response => {
        this.stats = response;
      });
    }
  }
};
</script>
